/* eslint-disable jsx-a11y/control-has-associated-label */

import React from "react"

import { Layout, Seo } from "../../layout"
import { IPhoneCarousel, img } from "../../components"

export default function IndexPage(props) {
  return (
    <Layout header="light" path={props.path}>
      <Seo title="Bli synlig på Trailguide" />

      <div
        className="relative w-full min-h-screen tg-front-image text-white px-4 sm:px-8"
        style={{
          backgroundImage: `url("${img("webapp/earnmoney.jpg")}")`,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="flex mx-auto max-w-4xl pt-24">
          <div>
            <h1 className="text-xl sm:text-3xl md:text-4xl lg:text-5xl text-left m-0">
              Gjør sykkelbutikken din tilgjengelig på Trailguide
            </h1>
            <p className="text-base sm:text-lg md:text-xl mt-8 md:mt-16">
              Trailguide er en app for stisykling utviklet i Norge. Den gir
              syklister enkel tilgang til et omfattende nettverk av stier som er
              lagt til av lokale sykkelentusiaster. Appen støtter også andre
              typer sykling som cross country og landeveg.
            </p>
            <p className="text-base sm:text-lg md:text-xl my-6">
              Appen har over 10.000 ukentlige brukere som leter etter nye stier
              eller får hjelp til guiding i terrenget. Kanskje på en sykkel som
              er kjøpt eller reparert akkurat hos dere.
            </p>
            <p className="text-base sm:text-lg md:text-xl my-6">
              Med en oppføring av sykkelbutikken din i Trailguide, sender vi deg
              en liten bunke klistremerker med en QR-kode som dine kunder kan
              skanne for å åpne Trailguide og komme seg ut på nye eventyr.
            </p>
          </div>
          <IPhoneCarousel
            top="-3.6%"
            images={[
              {
                label: "Eksempel på synlighet i Trailguide",
                src: img("/website/local-service-map-iphone-tretterud.jpg"),
              },
              {
                label: "Du er synlig på stier inntil 20km fra butikken din",
                src: img("/website/local-service-page-iphone-tretterud.jpeg"),
              },
            ]}
            resetClass="relative hidden md:block max-w-56 flex-none w-64 mt-8 ml-24"
            color="white"
          />
        </div>
      </div>
    </Layout>
  )
}
